import { assign, setup } from "xstate";

type OptimizeContext = {
  data: string;
};

type OptimizeEvents = { type: "change"; payload?: string };

const optimizeMachine = setup<OptimizeContext, OptimizeEvents>({
  types: {
    context: {} as OptimizeContext,
    events: {} as OptimizeEvents,
  },
  actions: {
    updateData: assign(({ context, event }) => ({
      ...context,
      data: event.payload,
    })),
  },
}).createMachine({
  id: "optimizeMachine",
  context: { data: "Sample data" },
  initial: "idle",
  states: {
    idle: {
      on: {
        // @ts-ignore
        change: {
          target: "changing",
          actions: ["updateData"],
        },
      },
    },
    changing: {
      after: {
        0: "idle",
      },
    },
    updating: {},
  },
});

export default optimizeMachine;
