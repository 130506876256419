import classNames from "classnames";

import { InputSize } from "@/@types";

import "./Input.scss";
import DatePicker from "./components/DatePicker.tsx";
import File from "./components/File.tsx";
import ImageUpload from "./components/ImageUpload.tsx";
import InputWrapper, { InputWrapperProps } from "./components/InputWrapper.tsx";
import Richtext from "./components/Richtext.tsx";
import TextArea from "./components/TextArea.tsx";

type LegacyWrapperProps = {
  /** @deprecated use size */
  inputSize?: InputSize;
  /** @deprecated use error */
  showError?: boolean;
  /** @deprecated use required */
  showRequiredIndicator?: boolean;
};

type InputProps = LegacyWrapperProps &
  InputWrapperProps & {
    size?: InputSize;
    error?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
  } & React.InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<InputProps> & {
  DatePicker: typeof DatePicker;
  TextArea: typeof TextArea;
  Richtext: typeof Richtext;
  File: typeof File;
  ImageUpload: typeof ImageUpload;
} = ({
  // LEGACY
  inputSize = undefined,
  showError = undefined,
  showRequiredIndicator,

  // WRAPPER
  label,
  description = undefined,
  marginReset = false,
  labelClassName,
  required,
  htmlFor,

  // INPUT
  inputRef = undefined,
  size = "standard",
  error = false,
  ...props
}) => {
  const trueSize = inputSize ?? size;
  const trueError = showError === undefined ? error : showError;
  const trueRequired =
    showRequiredIndicator === undefined ? required : showRequiredIndicator;

  const wrapperProps = {
    label,
    description,
    marginReset,
    labelClassName,
    required: trueRequired,
    htmlFor: htmlFor ?? props.id,
  };

  return (
    <InputWrapper {...wrapperProps}>
      <input
        {...props}
        required={required}
        className={classNames({
          "is-small": trueSize === "small",
          "is-wide": trueSize === "wide",
          "has-error": trueError,
        })}
        ref={inputRef}
      />
    </InputWrapper>
  );
};

Input.DatePicker = DatePicker;
Input.TextArea = TextArea;
Input.Richtext = Richtext;
Input.File = File;
Input.ImageUpload = ImageUpload;

export default Input;
