import { Actor, ActorRefFrom, SnapshotFrom, assign, setup } from "xstate";

import { setAccessToken, setRefreshToken } from "@/blackbox/storage/auth.ts";
import {
  getCompanyId,
  getOrganizationId,
  setCompanyId,
  setOrganizationId,
} from "@/blackbox/storage/user.ts";
import { UserDto, ZakekePermissionsDto } from "@/services";

import { getIntegration, logout, verifying } from "./actors.ts";
import loginMachine from "./login";

type AuthInput = {
  isIntegration: boolean;
};

type AuthContext = AuthInput & {
  user: UserDto | null;
  companyId: string | null;
  organizationId: string | null;
  error?: unknown;
  integration: ZakekePermissionsDto | null;
  loginActorRef: ActorRefFrom<typeof loginMachine> | null;
};

type AuthEvents =
  | { type: "verify" }
  | { type: "assignCompany"; payload: { companyId: string } }
  | {
      type: "assignOrganization";
      payload: { organizationId: string; resetCompany?: boolean };
    }
  | { type: "verifyIntegration"; payload: ZakekePermissionsDto }
  | { type: "assignIntegration"; payload: ZakekePermissionsDto }
  | { type: "logout" }
  | { type: "loadIntegration" };

export type AuthActor = Actor<typeof machine>;

const machine = setup({
  types: {
    context: {} as AuthContext,
    events: {} as AuthEvents,
    input: {} as AuthInput,
  },
  actions: {
    assignError: assign({
      error: ({ event }: { event: { error: unknown } }) => event.error,
    }),
    resetTokens: () => {
      setRefreshToken();
      setAccessToken();
    },
    resetUser: assign({
      user: null,
      companyId: null,
      organizationId: null,
    }),
  },
  actors: {
    logout: logout,
    verify: verifying,
    getIntegration: getIntegration,
  },
}).createMachine({
  context: ({ input }) => ({
    ...input,
    companyId: null,
    organizationId: null,
    user: null,
    integration: null,
    loginActorRef: null,
  }),
  on: {
    assignCompany: {
      actions: assign({
        companyId: ({ event }) => event.payload.companyId,
      }),
    },
    assignOrganization: {
      actions: assign({
        organizationId: ({ event }) => event.payload.organizationId,
        companyId: ({ context, event }) =>
          event.payload.resetCompany ? null : context.companyId,
      }),
    },
  },
  initial: "starting",
  states: {
    starting: {
      always: [
        {
          target: "verifying",
          guard: ({ context }) => !context.isIntegration,
        },
        {
          target: "unauthorized",
          guard: ({ context }) => context.isIntegration,
        },
      ],
    },
    unauthorized: {
      entry: [
        "resetUser",
        "resetTokens",
        assign({
          loginActorRef: ({ self, spawn }) =>
            spawn<typeof loginMachine>(loginMachine, {
              input: { authRef: self },
            }),
        }),
      ],
      on: {
        verify: "verifying",
        verifyIntegration: {
          target: "verifying",
          actions: assign({
            integration: ({ event }) => event.payload,
          }),
        },
      },
    },
    authorized: {
      entry: assign({
        loginActorRef: null,
      }),
      on: {
        verify: "verifying",
        logout: "invalidating",
        assignIntegration: {
          actions: assign({
            integration: ({ event }) => event.payload,
          }),
        },
        loadIntegration: "loadingIntegration",
      },
    },
    verifying: {
      invoke: {
        id: "verify",
        src: "verify",
        onDone: {
          target: "authorized",
          actions: [
            assign({
              user: ({ event }) => event.output,
              companyId: ({ context, event }) => {
                const searchParams = new URLSearchParams(
                  window.location.search,
                );

                return (
                  context.companyId ??
                  event.output.companyId ??
                  searchParams.get("companyId") ??
                  getCompanyId() ??
                  null
                );
              },
              organizationId: ({ context, event }) =>
                context.organizationId ??
                event.output.organizationId ??
                getOrganizationId() ??
                null,
            }),
          ],
        },
        onError: {
          target: "unauthorized",
        },
      },
    },
    invalidating: {
      invoke: {
        id: "logout",
        src: "logout",
        onDone: {
          target: "unauthorized",
        },
        onError: {
          target: "unauthorized",
          actions: ["assignError"],
        },
      },
    },
    loadingIntegration: {
      invoke: {
        id: "loading-integration",
        input: ({ context }) => ({ companyId: context.companyId! }),
        src: "getIntegration",
        onDone: {
          target: "authorized",
          actions: assign({
            integration: ({ event }) => event.output,
          }),
        },
        onError: {
          target: "unauthorized",
          actions: "assignError",
        },
      },
    },
    error: {},
  },
});

export const isAuthLoading = (
  snapshot: SnapshotFrom<typeof machine>,
): boolean => snapshot.matches("verifying") || snapshot.matches("invalidating");

export const registerOnChangeEvents = (authActor: AuthActor) => {
  const observer = authActor.subscribe((snapshot) => {
    setCompanyId(snapshot.context.companyId);
    setOrganizationId(snapshot.context.organizationId);
  });
  return observer.unsubscribe;
};

export default machine;
