import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import { ENV } from "./env";

const LOG_LEVELS = {
  TRACE: 10,
  DEBUG: 20,
  INFO: 30,
  WARN: 40,
  ERROR: 50,
};

const isSentryEnabled = ENV.NODE_ENV !== "development";

if (ENV.NODE_ENV !== "development") {
  Sentry.init({
    dsn: ENV.VITE_SENTRY_DSN,
    release: ENV.VITE_SENTRY_RELEASE,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
  });
}

let logLevel = LOG_LEVELS.WARN;

function isLogEnabled(level: number) {
  return level >= logLevel;
}

/**
 * @deprecated use console.log/debug etc directly
 *  eslint-disable no-console
 *
 **/
export function createLogger(context: string) {
  return {
    trace: (message: string, ...args: unknown[]) =>
      isLogEnabled(LOG_LEVELS.TRACE) &&
      console.trace(
        "%cTRACE",
        "background-color: violet; color: white; font-weight: bold;padding: 1px 2px",
        `[${context}]`,
        message,
        ...args,
      ),
    debug: (message: string, ...args: unknown[]) =>
      isLogEnabled(LOG_LEVELS.DEBUG) &&
      console.debug(
        "%cDEBUG",
        "background-color: violet; color: white; font-weight: bold;padding: 1px 2px",
        `[${context}]`,
        message,
        ...args,
      ),
    info: (message: string, ...args: unknown[]) =>
      isLogEnabled(LOG_LEVELS.INFO) &&
      console.log(
        "%cINFO",
        "background-color: blue; color: white; font-weight: bold;padding: 1px 2px",
        `[${context}]`,
        message,
        ...args,
      ),
    warn: (message: string, ...args: unknown[]) =>
      isLogEnabled(LOG_LEVELS.WARN) &&
      console.warn(
        "%cWARN",
        "background-color: orange; color: white; font-weight: bold;padding: 1px 2px",
        `[${context}]`,
        message,
        ...args,
      ),
    error: (error: Error, message: string, ...args: unknown[]) => {
      if (!isLogEnabled(LOG_LEVELS.ERROR)) return;
      isSentryEnabled &&
        Sentry.captureException(error, {
          extra: {
            message,
            ...(args ?? []),
          },
        });
      console.error(
        "%cERROR",
        "background-color: red; color: white; font-weight: bold;padding: 1px 2px",
        `[${context}]`,
        message,
        ...args,
      );
    },
  };
}

function enableLogs() {
  if (process.env.NODE_ENV !== "production") {
    logLevel = LOG_LEVELS.TRACE;
  }
}

enableLogs();
