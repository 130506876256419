const CSVpickerOpts = {
  types: [
    {
      description: "CSV",
      accept: {
        "text/*": [".csv", ".tsv"],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
};

const ImagePickerOpts = {
  types: [
    {
      description: "Images",
      accept: {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
};

const ZIPpickerOpts = {
  types: [
    {
      description: "Zip",
      accept: {
        "application/zip": [".zip"],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
};

const PDFpickerOpts = {
  types: [
    {
      description: "pdf",
      accept: {
        "application/pdf": [".pdf"],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
};

const openFilePolyfill = (
  pickerOpts: OpenFilePickerOptions,
): Promise<File[]> => {
  return new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = Boolean(pickerOpts.multiple);
    if (pickerOpts.types) {
      input.accept = pickerOpts.types
        .map((type) => type.accept)
        .flatMap((inst) => Object.keys(inst).flatMap((key) => inst[key]))
        .join(",");
    }

    input.addEventListener("change", () => {
      if (input.files) {
        const files = Array.from(input.files);
        resolve(files);
      }
    });

    input.click();
  });
};

/**
 * @deprecated please use an input html element directly in order to avoid unhandled behaviour
 */
export const openFiles = (
  pickerOpts: OpenFilePickerOptions,
): Promise<File[]> => {
  const isNotLoadedAsIFrame = window.location === window.parent.location;
  if (typeof window.showOpenFilePicker === "function" && isNotLoadedAsIFrame) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const fileHandles = await window.showOpenFilePicker(pickerOpts);
        const files = await Promise.all(
          fileHandles.map((fileHandle) => fileHandle.getFile()),
        );
        resolve(files);
      } catch (error) {
        reject(error);
      }
    });
  } else {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const files = await openFilePolyfill(pickerOpts);
        resolve(files);
      } catch (error) {
        reject(error);
      }
    });
  }
};

/**
 * @deprecated please use an input html element directly in order to avoid unhandled behaviour
 */
export const openFile = (pickerOpts: OpenFilePickerOptions): Promise<File> =>
  openFiles(pickerOpts).then((files) => files[0]);

/**
 * @deprecated please use an input html element directly in order to avoid unhandled behaviour
 */
export const openCSV = async (): Promise<File> => openFile(CSVpickerOpts);

/**
 * @deprecated please use an input html element directly in order to avoid unhandled behaviour
 */
export const openZip = async (): Promise<File> => openFile(ZIPpickerOpts);

/**
 * @deprecated please use an input html element directly in order to avoid unhandled behaviour
 */
export const openPdf = async (): Promise<File> => openFile(PDFpickerOpts);

/**
 * @deprecated please use an input html element directly in order to avoid unhandled behaviour
 */
export const openImage = async (): Promise<File> => openFile(ImagePickerOpts);

/**
 * @deprecated please use an input html element directly in order to avoid unhandled behaviour
 */
export const openImages = async (): Promise<File[]> =>
  openFiles({ ...ImagePickerOpts, multiple: true });
