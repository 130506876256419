import { useMachine } from "@xstate/react";
import SpinnerWrapper from "components/SpinnerWrapper";
import { useParams } from "react-router-dom";

import optimizeMachine from "@/blackbox/machines/optimization/optimize";
import PageHeader from "@/components/PageHeader";
import { OptimizeContext } from "@/contexts/Optimize";
import { MainPageLayout } from "@/layouts";
import Template from "@/templates/Optimize/Template.tsx";

export default function Optimize() {
  const { id, type } = useParams();
  const [state, send, optimizeActor] = useMachine(optimizeMachine);

  return (
    <OptimizeContext.Provider value={optimizeActor}>
      <MainPageLayout
        header={
          <PageHeader
            title={"Optimization for 3D models"}
            subtitle={
              "Optimization settings will automatically reduce the 3d model file size, poly count, vertex count, and texture size so the 3d model can be viewed in real time rendering and augmented reality without losing quality."
            }
            className={"tw-pb-2"}
          ></PageHeader>
        }
      >
        <SpinnerWrapper
          spinning={
            // @ts-ignore
            state.matches("changing") ||
            // @ts-ignore
            state.matches("updating")
          }
        >
          <p>Id param: {id}</p>
          <p>Type param: {type}</p>
          <button
            onClick={() => send({ type: "change", payload: "New sample data" })}
          >
            Change DATA
          </button>
          <Template />
        </SpinnerWrapper>
      </MainPageLayout>
    </OptimizeContext.Provider>
  );
}
