import {
  LoginZakekeResDto,
  UserDto,
  UserGroupCode,
  ZakekeAddonDto,
} from "@/services";

export type LoggedUser = UserDto;

export type ZakekeAddon = ZakekeAddonDto;
export type ZakekeAuthData = LoginZakekeResDto | null;

export const ADDON_PREMIUM = "premium";
export const ADDON_VTO = "vto";

export type CognitoUserAttributes = {
  username: string;
  emailVerified: boolean;
  identities?: {
    providerName: string;
  }[];
  /** @deprecated Groups list from cognito. Use groupId instead which is read from DB */
  groups: UserGroupCode[];
};

export const VIEW_GROUP: UserGroupCode[] = [
  "ffsuperadmin",
  "ffadmin",
  "orgadmin",
  "customer",
  "customerview",
];

export const VIEW_GROUP_WITH_PM: UserGroupCode[] = [...VIEW_GROUP, "pm"];

export const VIEW_GROUP_WITH_PM_AND_3DARTIST: UserGroupCode[] = [
  ...VIEW_GROUP_WITH_PM,
  "artist3d",
];

export const VIEW_GROUP_WITH_PROSPECT: UserGroupCode[] = [
  ...VIEW_GROUP,
  "prospect",
];

export const groupCodeOptions = [
  {
    value: "ffsuperadmin",
    label: "Super Admin",
  },
  {
    value: "ffadmin",
    label: "FF Admin",
  },
  {
    value: "orgadmin",
    label: "Org Admin",
  },
  {
    value: "pm",
    label: "Project Manager",
  },
  {
    value: "artist3d",
    label: "3D Artist",
  },
  {
    value: "customer",
    label: "Customer",
  },
  {
    value: "customerview",
    label: "Customer View",
  },
  {
    value: "prospect",
    label: "Prospect",
  },
];
