import {
  AuthResponse,
  LoginResponse,
  authHttpClient,
} from "services/http/auth";
import { fromPromise } from "xstate";

import { getRefreshToken, setAccessToken } from "@/blackbox/storage/auth.ts";
import {
  UserDto,
  ZakekePermissionsDto,
  rpcHttpClient,
  usersHttpClient,
} from "@/services";

export type AuthenticationInput = {
  username: string;
  password: string;
};

export const login = fromPromise<LoginResponse, AuthenticationInput>(
  async ({ input: { username, password } }) =>
    authHttpClient.login(username, password),
);

export type ChallengeInput = {
  username: string;
  newPassword: string;
  session: string;
};

export const challenge = fromPromise<AuthResponse, ChallengeInput>(
  ({ input: { username, newPassword, session } }) =>
    authHttpClient.respondToChallenge(username, newPassword, session),
);

export const verifying = fromPromise<UserDto>(async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return Promise.reject("no token found");
  }

  try {
    // if this call succeeds we know:
    // - the token is already set
    // - the token is valid
    return await usersHttpClient.get();
  } catch (e) {
    if (!(e as Error).message.includes('"statusCode":401')) {
      throw e;
    }
  }

  const response = await authHttpClient.refresh(refreshToken);

  // this is technically a side effect, can we somehow avoid it?
  // on the other hand it is clear what is done
  setAccessToken(response.token!);
  return await usersHttpClient.get();
});

export const logout = fromPromise(async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) return Promise.reject("no suitable refresh token found");
  return authHttpClient.logout(refreshToken);
});

export type ChangePasswordInput = {
  newPassword: string;
  oldPassword: string;
};

export const changePassword = fromPromise<void, ChangePasswordInput>(
  async ({ input: { newPassword, oldPassword } }) =>
    authHttpClient.changePassword(oldPassword, newPassword),
);

export type RegisterInput = {
  username: string;
};

export const register = fromPromise<void, RegisterInput>(
  async ({ input: { username } }) => authHttpClient.register(username),
);

export type AuthorizeIntegrationInput = {
  token: string;
};

export type AuthorizeIntegrationResponse = AuthResponse & {
  integration: ZakekePermissionsDto;
};

export const authorizeIntegration = fromPromise<
  AuthorizeIntegrationResponse,
  AuthorizeIntegrationInput
>(async ({ input: { token } }) => {
  const resp = await rpcHttpClient.user.loginZakeke({ zakekeToken: token });

  return {
    token: resp.accessToken,
    refreshToken: resp.refreshToken,
    integration: resp.zakekePermissions,
  };
});

export type GetIntegrationInput = {
  companyId: string;
};

export const getIntegration = fromPromise<
  ZakekePermissionsDto,
  GetIntegrationInput
>(async ({ input: { companyId } }) => {
  const resp = await rpcHttpClient.user.permissionsZakeke(companyId);
  return resp.zakekePermissions;
});
