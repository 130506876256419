import { useContext } from "react";

import { OptimizeContext } from "@/contexts/Optimize.ts";

const useOptimizeActor = () => {
  const actor = useContext(OptimizeContext);

  if (!actor) {
    throw new Error("useActors hook not called in the OptimizeContext");
  }

  return actor;
};

export default useOptimizeActor;
