import { TextButton, ZTooltip } from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";
import React, { useMemo, useState } from "react";

import { ReactComponent as TooltipIcon } from "@/svg/tooltip-icon.svg?react";

import { InputWrapperProps } from "./InputWrapper.tsx";

type FileProps = Omit<InputWrapperProps, "htmlFor"> & {
  error?: boolean;
  buttonLabel?: string;
  tooltip?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "id">;

const File: React.FC<FileProps> = ({
  buttonLabel,
  tooltip,

  // WRAPPER
  label,
  description = undefined,
  marginReset = false,
  labelClassName,
  required,
  className,

  // INPUT
  // error = false,
  onChange,
  ...props
}) => {
  const uniqueId = useMemo(() => crypto.randomUUID(), []);

  const [_fileName, _setFileName] = useState<string>("No file selected");

  const _onChange: FileProps["onChange"] = (ev) => {
    onChange?.(ev);
    if (ev.target.files && ev.target.files?.length > 0) {
      _setFileName(
        Array.from(ev.target.files)
          .map((file) => file.name)
          .join(", "),
      );
    } else {
      _setFileName("No file selected");
    }
  };

  return (
    <div
      id="ad4068f0d304a56"
      className={classNames("input-wrapper", "file-input", className, {
        "has-no-margin": marginReset,
      })}
    >
      <input
        {...props}
        onChange={_onChange}
        id={uniqueId}
        required={required}
        type="file"
      />
      {label && (
        <label htmlFor={uniqueId}>
          <div className={classNames("file-label", labelClassName)}>
            {label} {required && <span className="is-required">*</span>}{" "}
            {tooltip && (
              <ZTooltip title={tooltip}>
                <span className={"tooltip"}>
                  <TooltipIcon />
                </span>
              </ZTooltip>
            )}
          </div>
          <div className="file-chooser">
            <TextButton type="primary">
              {buttonLabel ?? "Choose File"}
            </TextButton>
            <span
              className={classNames("filename", {
                "filename-empty": _fileName === "No file selected",
              })}
            >
              {_fileName}
            </span>
          </div>
        </label>
      )}
      {description && <div className="description">{description}</div>}
    </div>
  );
};

export default File;
