import { useSelector } from "@xstate/react";
import FullscreenLoader from "components/FullscreenLoader";
import { Suspense } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";

import { Language } from "@/@types";
import { SuiteLogo } from "@/components/SuiteLogo";
import { useI18n } from "@/hooks";
import useAuth from "@/hooks/auth/useAuth.ts";

import "./AuthLayout.scss";

// Components in the Components directory should NOT access global context
// and only operate through passed props. AuthLayout in this regard is special
// because the entire login / reset-password / auth flow is particular.
// Furthermore we are parking the layout components in the global components
// dir in order to avoid polluting the layout dir with correct stuff
// since it is a deprecated folder
const AuthLayout = () => {
  const { changeLanguage } = useI18n();
  const location = useLocation();
  const authActor = useAuth();
  const isAlreadyAuthorized = useSelector(authActor, (state) =>
    state.matches("authorized"),
  );

  if (isAlreadyAuthorized) {
    const params = new URLSearchParams(location.search);
    if (params.has("return_url")) {
      const tempUrl = new URL(
        params.get("return_url")!,
        "http://futurefashion.com",
      );
      const redirParams = new URLSearchParams(tempUrl.search);
      if (redirParams.has("culture")) {
        changeLanguage(redirParams.get("culture") as Language);
      }
    }
    return (
      <Navigate
        to={params.get("redirect") ?? params.get("return_url") ?? "/gallery"}
        replace
      />
    );
  }

  return (
    <main className="auth-layout">
      <header className="header">
        <Link to={"/"}>
          <SuiteLogo />
        </Link>
      </header>
      <h1 className="title">3D Digital Asset Manager</h1>
      <section className="content">
        {isAlreadyAuthorized && <FullscreenLoader absolute />}
        <Suspense fallback={<FullscreenLoader absolute />}>
          <Outlet />
        </Suspense>
      </section>
    </main>
  );
};

export default AuthLayout;
