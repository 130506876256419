import { Spinner } from "@zakeke/zakeke-ui-widgets";
import classnames from "classnames";

import "./SpinnerWrapper.scss";

interface SpinnerWrapperProps {
  spinning?: boolean;
  children?: React.ReactNode;
  showProgress?: boolean;
  progress?: number;
}

const SpinnerWrapper: React.FC<SpinnerWrapperProps> = ({
  children,
  spinning = false,
  showProgress = false,
  progress,
}) => {
  return (
    <div className={classnames("spinner-wrapper", { spinning })}>
      <div className="content">{children}</div>
      <div className="overlay">
        <Spinner />
        {showProgress && (
          <div className="spinner-progress">
            <div
              className="progress"
              style={{
                width: `${progress}%`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SpinnerWrapper;
