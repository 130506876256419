import { createLogger } from "./logger";

const logger = createLogger("isScrollAtBottom");

type isScrollAtBottomParams = {
  scrollHeight: number;
  containerHeight: number;
  scrollTop: number;
};

export const isScrollAtBottom = ({
  scrollHeight,
  scrollTop,
  containerHeight,
}: isScrollAtBottomParams): boolean => {
  logger.info(
    "scrollHeight - scrollTop:",
    scrollHeight - scrollTop,
    "containerHeight:",
    containerHeight,
  );
  return scrollHeight - scrollTop <= containerHeight + 1; // +1 for rounding error
};
