import {
  DialogsRenderer,
  DialogsStateProvider,
  NotificationToasterRenderer,
} from "@zakeke/zakeke-ui-widgets";
import "@zakeke/zakeke-ui-widgets/dist/style.css";
import { ThemeProvider } from "styled-components";

import { AsyncLoader } from "@/components/AsyncLoader/index.tsx";
import ErrorBoundary from "@/components/ErrorBoundary";
import { Loader } from "@/components/Loader/index.tsx";
import { useLoading } from "@/hooks/useLoading.hook.tsx";
import { AppContextProvider } from "@/libs/combineComponents.tsx";
import Router from "@/pages/Router.tsx";
import theme from "@/theme/theme";

import "./App.scss";
import "./configuration/i18n";
import "./tailwind.css";

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <DialogsStateProvider>
          <DialogsRenderer />
          <NotificationToasterRenderer />
          <AppContextProvider>
            <LoaderComponent />
            <Router />
          </AppContextProvider>
        </DialogsStateProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;

function LoaderComponent() {
  const isLoading = useLoading((s) => s.isLoading);
  const isAsyncLoading = useLoading((s) => s.isAsyncLoading);
  return (
    <>
      {isLoading && <Loader />}
      {isAsyncLoading && <AsyncLoader text="Loading..." />}
    </>
  );
}
