import { useSelector } from "@xstate/react";

import useOptimizeActor from "./hooks/useOptimizeActor";

export default function Template() {
  const optimizeActor = useOptimizeActor();

  const data = useSelector(optimizeActor, (snap) => snap.context.data);

  return (
    <>
      <p>DATA: {data}</p>
    </>
  );
}
