import { ZTooltip } from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";
import React, { useMemo, useState } from "react";

import { ReactComponent as TooltipIcon } from "@/svg/tooltip-icon.svg?react";
import { ReactComponent as UploadIcon } from "@/svg/upload-solid.svg?react";

import { InputWrapperProps } from "./InputWrapper.tsx";

type ImageUploadProps = Omit<InputWrapperProps, "htmlFor"> & {
  error?: boolean;
  tooltip?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "id">;

const ImageUpload: React.FC<ImageUploadProps> = ({
  tooltip,

  // WRAPPER
  label,
  description = undefined,
  marginReset = false,
  labelClassName,
  required,
  className,

  // INPUT
  // error = false,
  onChange,
  ...props
}) => {
  const uniqueId = useMemo(() => crypto.randomUUID(), []);

  const [_imagePreview, _setImagePreview] = useState<string[]>([]);

  const _onChange: ImageUploadProps["onChange"] = (ev) => {
    onChange?.(ev);
    if (ev.target.files && ev.target.files?.length > 0) {
      _setImagePreview(
        Array.from(ev.target.files).map((file) => URL.createObjectURL(file)),
      );
    } else {
      _setImagePreview([]);
    }
  };

  return (
    <div
      id="ad4068f0d304a56"
      className={classNames("input-wrapper", "file-input", className, {
        "has-no-margin": marginReset,
      })}
    >
      <input
        {...props}
        onChange={_onChange}
        id={uniqueId}
        required={required}
        type="file"
      />
      {label && (
        <label htmlFor={uniqueId}>
          <div className={classNames("file-label", labelClassName)}>
            {label} {required && <span className="is-required">*</span>}{" "}
            {tooltip && (
              <ZTooltip title={tooltip}>
                <span className={"tooltip"}>
                  <TooltipIcon />
                </span>
              </ZTooltip>
            )}
          </div>
          <div className="tw-w-full tw-border tw-border-dashed tw-border-[#8FA4AE] tw-bg-[#FAFBFB] tw-box-border tw-rounded-lg tw-flex tw-justify-center tw-cursor-pointer tw-mt-2">
            {_imagePreview.length ? (
              <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-p-2">
                {_imagePreview.map((url, index) => (
                  <img className="tw-max-w-full" src={url} key={index} />
                ))}
              </div>
            ) : (
              <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3 tw-py-8">
                <UploadIcon className="tw-w-10 tw-h-10" />
                <span className="tw-text-sm	tw-font-bold">Upload Image</span>
              </div>
            )}
          </div>
        </label>
      )}
      {description && <div className="description">{description}</div>}
    </div>
  );
};

export default ImageUpload;
